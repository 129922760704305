import {resizeMediaByWidth} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ICartItem} from '../../types/app.types';

type MediaDimensions = {width: number; height: number};

export const getMediaDimensions = (item: ICartItem, defaultDimensions: MediaDimensions): MediaDimensions => {
  const primaryMedia = item.product.media[0];

  if (primaryMedia) {
    return resizeMediaByWidth(primaryMedia, 100);
  }

  return defaultDimensions;
};

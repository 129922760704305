import React from 'react';
import {useTranslation} from 'yoshi-flow-editor-runtime';
import {useControllerProps} from '../../../ControllerContext';
import {OrderSummaryDataHook} from '../OrderSummary';
import commonStyles from '../OrderSummary.scss';
import s from './CouponTotal.scss';
import classnames from 'classnames';

export const CouponTotal = () => {
  const {t} = useTranslation();
  const {appliedCoupon, convertedTotals} = useControllerProps().cartStore.cart;

  return (
    <div data-hook={OrderSummaryDataHook.CouponRoot} className={classnames(commonStyles.divider, s.root)}>
      <dl>
        <dt>
          <span data-hook={OrderSummaryDataHook.CouponLabel}>{t('cart.coupon')}</span>{' '}
          <span data-hook={OrderSummaryDataHook.CouponName}>{appliedCoupon.name}</span>
        </dt>
        <dd data-hook={OrderSummaryDataHook.CouponDiscountValue}>-{convertedTotals.formattedDiscount}</dd>
      </dl>
    </div>
  );
};


  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/1f82798b85adcd2d/client/wixstores-client/wixstores-client-cart-ooi/src/components/cart/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/agent00/work/1f82798b85adcd2d/client/wixstores-client/wixstores-client-cart-ooi/src/components/cart/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://7a9383877d1648169973b9d6339b753b@o37417.ingest.sentry.io/5552111',
      id: '7a9383877d1648169973b9d6339b753b',
      projectName: 'wixstores-client-cart-ooi',
      teamName: 'wixstores',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'cart'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };

import React from 'react';
import {IControllerProps} from '../../../types/app.types';
import {WidgetProps} from 'yoshi-flow-editor-runtime';

type VoidReturn<T> = T extends (...args: infer P) => unknown ? (...args: P) => void : T;
type VoidMethodsDeep<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends Record<string, unknown> ? VoidMethodsDeep<T[K]> : VoidReturn<T[K]>;
};
export type UseControllerPropsResult = WidgetProps<VoidMethodsDeep<IControllerProps>>;

const Context = React.createContext({} as WidgetProps<IControllerProps>);

export function useControllerProps(): UseControllerPropsResult {
  return React.useContext(Context);
}

export function ControllerContext({
  value,
  children,
}: {
  value: WidgetProps<IControllerProps>;
  children: React.ReactNode;
}) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

import React from 'react';
import {Loader as LoaderAnimation} from '@wix/wixstores-client-common-components/dist/src/Loader';
import s from './Loader.scss';

export const Loader = () => {
  return (
    <div className={s.loader}>
      <LoaderAnimation />
    </div>
  );
};

import React from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {useControllerProps} from '../../../../ControllerContext';
import {ShippingDestination} from '../ShippingDestination/ShippingDestination';
import {useTranslation} from 'yoshi-flow-editor-runtime';

const ShippingDestinationAndShippingRates = () => {
  const {t} = useTranslation();
  const {shouldDisplayDeliveryError, isPickup} = useControllerProps().orderStore;
  const {cart} = useControllerProps().cartStore;

  const displayPrice = !shouldDisplayDeliveryError;
  const displayPickup = isPickup;
  return (
    <dl>
      <dt>
        <div data-hook={OrderSummaryDataHook.TotalShippingShippingDestinationWithTitle}>
          <span data-hook={OrderSummaryDataHook.TotalShippingTitle}>
            {displayPickup ? t('cart.estimated_pickup') : t('cart.estimated_shipping')}
          </span>
          &nbsp;
          <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationRoot} />
        </div>
      </dt>
      {displayPrice && (
        <dd data-hook={OrderSummaryDataHook.TotalShippingEstimatedShipping}>
          {cart.convertedTotals.shipping > 0 ? cart.convertedTotals.formattedShipping : t('cart.free_shipping')}
        </dd>
      )}
    </dl>
  );
};

export const TotalShipping = () => {
  const {isEstimatedDestinationIsShippable} = useControllerProps().orderStore;

  return (
    <div data-hook={OrderSummaryDataHook.TotalShippingRoot}>
      {isEstimatedDestinationIsShippable ? (
        <ShippingDestinationAndShippingRates />
      ) : (
        <dt>
          <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationRoot} />
        </dt>
      )}
    </div>
  );
};
